import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import logo from "../images/logo.png"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasScrolled: false,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  handleScroll = event => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 50) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false })
    }
  }

  render() {
    return (
      <Navbar
        collapseOnSelect
        bg={this.state.hasScrolled ? "light" : "transparent"}
        expand="lg"
        fixed="top"
      >
        <Navbar.Brand href="#home">
          <img
            classname="image-responsive"
            src={logo}
            height="50"
            width="50"
            alt=""
            style={{ marginLeft: "35px" }}
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end custom_nav_background"
        >
          <Nav className="ml-auto menu">
            <Nav.Link className="mr-5-desktop" href="#home">
              Home
            </Nav.Link>
            <Nav.Link className="mr-5-desktop inactive" href="#services">
              Services
            </Nav.Link>
            <Nav.Link className="mr-5-desktop menu-item" href="#why-us">
              Why Us
            </Nav.Link>
            <Nav.Link className="mr-5-desktop menu-item" href="#about">
              About Us
            </Nav.Link>
            <Nav.Link className="mr-5-desktop menu-item" href="#contact">
              Contact Us
            </Nav.Link>
            {/* <Nav.Link className="mr-5-desktop menu-item" href="#link">
              Privacy Policy
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default Header
